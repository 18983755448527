import { Text } from '@chakra-ui/react'
import { FlashCardContext } from 'features/flashcard/flashcard.context'
import { useContext } from 'react'

export function Answer() {
  const answer = useContext(FlashCardContext).answer
  return (
    <Text fontSize="md" style={{ whiteSpace: 'pre-line' }}>
      {answer}
    </Text>
  )
}
