import { StarIcon } from '@chakra-ui/icons'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { BookExternal } from 'shared/api/book.model'

interface ExternalBookProps {
  book: BookExternal
  onAddBook: () => void
}

export const ExternalBook = ({ book, onAddBook }: ExternalBookProps) => {
  return (
    <>
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow="hidden"
        variant="outline"
        style={{ borderColor: '#2F855A' }}
      >
        <Image
          objectFit="cover"
          maxW={{ base: '100%', sm: '200px' }}
          src={book.cover}
          alt="Caffe Latte"
        />

        <Stack style={{ width: '100%' }}>
          <CardBody>
            <Heading size="md">{book.title}</Heading>
            <Badge>{book.externalServiceId}</Badge>
            <Text py="2">{book.author?.fullname}</Text>
          </CardBody>

          <CardFooter>
            <div style={{ marginLeft: 'auto' }}>
              <Heading size="xl">
                <StarIcon style={{ marginTop: '-6px' }} color="orange" />{' '}
                {book.rating}{' '}
              </Heading>
              <Tooltip label={book.possess ? 'Already possess' : ''}>
                <Button
                  variant="solid"
                  bgColor="green.600"
                  mt="4"
                  onClick={onAddBook}
                  isDisabled={book.possess}
                >
                  Add book
                </Button>
              </Tooltip>
            </div>
          </CardFooter>
        </Stack>
      </Card>
    </>
  )
}
