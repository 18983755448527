import {
  Badge,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react'
import { FlashCardContext } from 'features/flashcard/flashcard.context'
import { useContext } from 'react'

export function PingBadge() {
  const ping = useContext(FlashCardContext).ping || []
  return (
    <Popover>
      <PopoverTrigger>
        <Badge
          style={{
            float: 'right',
            margin: '-12px -8px 2px 0',
            cursor: 'pointer',
          }}
          variant="outline"
          colorScheme="yellow"
          bgColor="white"
        >
          {ping.slice(-1)}
        </Badge>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>You viewed flash at:</PopoverHeader>
        <PopoverBody>
          {ping.map((p, index) => {
            return <p key={p + index}>{p}</p>
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
