import { Heading } from '@chakra-ui/react'
import { FlashCardContext } from 'features/flashcard/flashcard.context'
import { useContext } from 'react'

export function Question() {
  const question = useContext(FlashCardContext).question
  return (
    <>
      <Heading as="h2" size="md">
        {question}
      </Heading>
    </>
  )
}
