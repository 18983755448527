import { Button, Input } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

interface LockerProp {
  children: React.ReactElement
}

export const Locker = ({ children }: LockerProp) => {
  const [keyInput, setKeyInput] = useState<string>('')
  const [key, setKey] = useState<string>(localStorage.getItem('key') || '')

  useEffect(() => {
    localStorage.setItem('key', key)
  }, [key])

  if (key && key === 'ania2023') {
    return <>{children}</>
  } else {
    return (
      <>
        <Input
          placeholder="Put secret"
          value={keyInput}
          onChange={(e) => setKeyInput(e.target.value)}
        />
        <Button onClick={() => setKey(keyInput)}>Set</Button>
      </>
    )
  }
}
