import { Container } from '@chakra-ui/react'
import { AddTaskWise } from 'features/add-task/AddTaskWise'
import { AddBookWise } from 'features/book/add/AddBookWise'
import { LibraryWise } from 'features/book/library/LibraryWise'
import { DashboardWise } from 'features/dashboard/DashboardWise'
import { AddFilmWise } from 'features/film/add-film/AddFilmWise'
import { FilmLibraryWise } from 'features/film/library/FilmLibraryWise'
import { FlashcardList } from 'features/flashcard/flashcard-list'
import { FooterStupid } from 'features/footer/FooterStupid'
import NavigationStupid from 'features/navigation/NavigationStupid'
import { Note } from 'features/note/Note'
import React from 'react'
import 'App.css'
import 'flex.css'
import { Route } from 'wouter'

function App() {
  return (
    <>
      <NavigationStupid />
      <Container
        maxW="container.xl"
        centerContent
        style={{ padding: '1rem' }}
        id="page-container"
      >
        <div id="content-wrap" style={{ width: '100%' }}>
          <Route path="/">
            <DashboardWise />
          </Route>
          <Route path="/task/add">
            <AddTaskWise />
          </Route>
          <Route path="/book/add">
            <AddBookWise />
          </Route>
          <Route path="/books">
            <LibraryWise />
          </Route>
          <Route path="/film">
            <FilmLibraryWise />
          </Route>
          <Route path="/film/add">
            <AddFilmWise />
          </Route>
          <Route path="/note">
            <Note />
          </Route>
          <Route path="/flashcard">
            <FlashcardList />
          </Route>
        </div>
      </Container>
      <FooterStupid></FooterStupid>
    </>
  )
}

export default App
