import { Center, SimpleGrid } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  fetchAllTasks,
  insertTask,
  removeTask,
  updateTask,
} from 'shared/api/task-api'
import { QuickAddTask, Task } from 'shared/api/task.model'
import { replaceAllFields } from 'shared/utils/collection'
import { PlannerDay } from 'shared/utils/PlannerDay'
import { TaskListWise } from 'features/dashboard/task/task-list/TaskListWise'

interface TaskContainerWiseProps {
  day: PlannerDay
}

interface DaysInPlaList {
  isMain: boolean
  date: PlannerDay
}

export const TaskContainerWise = ({ day }: TaskContainerWiseProps) => {
  const [tasks, setTasks] = useState<Task[]>([])

  useEffect(() => {
    fetchAllTasks().then((response) => setTasks(response.data))
  }, [])

  function handleTaskEdit(task: Task, afterSaveCallback?: () => void) {
    const updatedTasks = [...tasks]
    const find = updatedTasks.find((t) => t._id === task._id)
    if (find) {
      replaceAllFields(find, task)
      updateTask(task).then((taskAfter) => {
        const list = [...tasks]
        const findAfter = list.find((t) => t._id === task._id)
        if (findAfter) {
          replaceAllFields(findAfter, taskAfter.data)
          setTasks(list)
        }
        if (afterSaveCallback) afterSaveCallback()
      })
    }
  }

  function calculateTaskListOrderView() {
    const items: DaysInPlaList[] = []
    if (day.isoWeekday() === 1) {
      items.push({ isMain: true, date: day })
      items.push({ isMain: false, date: day.addDay(1) })
      items.push({ isMain: false, date: day.addDay(2) })
    } else if (day.isoWeekday() === 5) {
      items.push({ isMain: false, date: day.addDay(-2) })
      items.push({ isMain: false, date: day.addDay(-1) })
      items.push({ isMain: true, date: day })
    } else {
      items.push({ isMain: false, date: day.addDay(-1) })
      items.push({ isMain: true, date: day })
      items.push({ isMain: false, date: day.addDay(1) })
    }
    return items
  }

  const taskListView = calculateTaskListOrderView()

  function handleTaskQuickAdd(title: string, date: string) {
    const task: QuickAddTask = {
      date,
      title,
    }
    insertTask(task).then((task) => {
      setTasks([...tasks, task.data])
    })
  }

  function handleTaskRemove(id: string) {
    removeTask(id).then(() => {
      setTasks(tasks.filter((t) => t._id !== id))
    })
  }

  return (
    <>
      <Center>
        <SimpleGrid gap={3} columns={[1, 1, 3]} maxW={1200}>
          {taskListView.map((item) => (
            <TaskListWise
              key={item.date.hash()}
              isMain={item.isMain}
              tasks={tasks.filter((task) => task.date === item.date.getDate())}
              onTaskEdit={handleTaskEdit}
              onTaskQuickAdd={(title) =>
                handleTaskQuickAdd(title, item.date.getDate())
              }
              onTaskRemove={handleTaskRemove}
            />
          ))}
        </SimpleGrid>
      </Center>
    </>
  )
}
