import { Tab } from '@chakra-ui/react'
import { PlannerDay } from 'shared/utils/PlannerDay'

interface CalendarItemStupidProps {
  day: PlannerDay
  onSelectDay: () => any
}

export const CalendarItemStupid = ({
  day,
  onSelectDay,
}: CalendarItemStupidProps) => {
  return (
    <>
      <Tab onClick={onSelectDay}>{day.getWeekname()}</Tab>
    </>
  )
}
