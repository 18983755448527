import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react'
import { FlashCardContext } from 'features/flashcard/flashcard.context'
import React, { ChangeEvent, useContext, useState } from 'react'
import * as FlashcardAPI from 'shared/api/flashcard-api'
import { Flashcard } from 'shared/api/flashcard.model'

interface EditFlashcardProps {
  onRefresh: (flashcard: Flashcard) => Promise<void>
}

export default function EditFlashcard({ onRefresh }: EditFlashcardProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [question, setQuestion] = useState<string>(
    useContext(FlashCardContext).question,
  )
  const [answer, setAnswer] = useState<string>(
    useContext(FlashCardContext).answer,
  )

  const _id = useContext(FlashCardContext)._id

  const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value)
  }

  const handleAnswerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(e.target.value)
  }

  const handleEdit = async () => {
    setIsLoading(true)

    const updated = await FlashcardAPI.update({
      _id,
      answer,
      question,
    })

    setQuestion('')
    setAnswer('')

    setIsLoading(false)
    return await onRefresh(updated.data)
  }

  return (
    <Flex direction="column">
      <FormControl mb={4}>
        <FormLabel htmlFor="question">Question</FormLabel>
        <Input
          id="question"
          placeholder="Enter your question"
          value={question}
          onChange={handleQuestionChange}
          disabled={isLoading}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel htmlFor="answer">Answer</FormLabel>
        <Textarea
          id="answer"
          placeholder="Enter your answer"
          value={answer}
          onChange={handleAnswerChange}
          resize="vertical"
          minHeight="200px"
          disabled={isLoading}
        />
      </FormControl>

      <Button
        colorScheme="yellow"
        bgColor="planner.yellow"
        isDisabled={isLoading || !question || !answer}
        onClick={handleEdit}
      >
        Edit
      </Button>
    </Flex>
  )
}
