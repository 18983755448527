import { Icon } from '@chakra-ui/icons'

type TaskIconProps = {
  completed: boolean
  setCompleted: () => void
}

type DoneIconProps = {
  color?: string
}

function DoneIcon({ color }: DoneIconProps) {
  const iconStyle = {
    width: '12px',
    height: '12px',
    marginTop: '-6px',
    marginRight: '3px',
  }

  return (
    <Icon
      viewBox="0 0 512 512"
      preserveAspectRatio="xMidYMid meet"
      fill={color}
      stroke="none"
      style={iconStyle}
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path
          d="M2315 5109 c-884 -91 -1661 -631 -2049 -1423 -93 -188 -137 -308
-186 -500 -61 -240 -74 -355 -74 -626 0 -271 13 -386 74 -626 49 -192 93 -312
185 -500 248 -505 663 -920 1170 -1169 248 -122 496 -199 770 -241 169 -26
541 -26 710 0 562 85 1054 333 1450 731 428 429 676 947 740 1545 8 74 15 163
15 198 l0 62 -254 0 -253 0 -7 -102 c-65 -980 -763 -1757 -1726 -1920 -166
-28 -454 -30 -615 -5 -448 72 -836 269 -1150 582 -594 593 -766 1481 -436
2247 103 240 248 454 431 638 353 353 785 555 1285 601 203 18 469 -2 655 -51
l75 -19 202 201 c112 112 199 206 195 210 -25 25 -430 125 -627 154 -123 18
-458 26 -580 13z"
        />
        <path
          d="M3410 3355 c-602 -602 -1099 -1095 -1105 -1095 -5 0 -187 177 -402
392 l-393 393 -175 -175 c-96 -96 -175 -178 -175 -181 0 -3 257 -263 572 -578
l571 -572 1281 1280 1281 1281 -175 175 c-96 96 -177 175 -180 175 -3 0 -498
-493 -1100 -1095z"
        />
      </g>
    </Icon>
  )
}

export function TaskItemIconStupid({ completed, setCompleted }: TaskIconProps) {
  console.log(completed)
  return (
    <div style={{ cursor: 'pointer' }} onClick={setCompleted}>
      {!completed && <DoneIcon />}
      {completed && <DoneIcon color="green" />}
    </div>
  )
}
