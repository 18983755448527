import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Film } from 'shared/api/film.model'
import { FilmEditStupid } from 'features/film/film-edit/FilmEditStupid'

interface FilmEditWiseProps {
  film: Film
  isOpen: boolean
  onClose: () => void
  onFilmSave: (film: Film) => void
  onFilmDelete: () => void
}

export const FilmEditModalWise = ({
  film,
  onFilmSave,
  isOpen,
  onClose,
  onFilmDelete,
}: FilmEditWiseProps) => {
  const [editFilm, setEditFilm] = useState<Film>(film)
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Edit film
            <Button
              colorScheme="red"
              style={{
                float: 'right',
                marginRight: '20px',
              }}
              onClick={onFilmDelete}
            >
              Delete
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FilmEditStupid film={editFilm} setFilm={setEditFilm} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" onClick={() => onFilmSave(editFilm)}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
