export type BookExternal = {
  title: string
  url: string
  cover: string
  rating: string
  externalServiceId: number
  possess: boolean
  author: {
    fullname: string
    id: 'string'
    link: 'string'
    url: 'string'
  }
}

type BookDetails = Omit<BookExternal, 'possess'>

export type Book = {
  _id: string
  addedDate: string
  externalServiceId: number
  status: BookStatus
  carrier: BookCarrier
  rate?: number
  startReading: string
  endReading: string
  review: string
  details: BookDetails
}

export enum BookStatus {
  TO_READ = 'TO_READ',
  READING = 'READING',
  READ = 'READ',
}

export enum BookCarrier {
  NOT_OWNED = 'NOT_OWNED',
  EBOOK = 'EBOOK',
  AUDIOBOOK = 'AUDIOBOOK',
  PAPER = 'PAPER',
}

export interface CreateBookDto {
  addedDate: string
  externalServiceId: number
  title: string
  status: BookStatus
  carrier: BookCarrier
  rate?: number
}
