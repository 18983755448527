import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
} from '@chakra-ui/react'
import React from 'react'
import { Film, FilmStatus } from 'shared/api/film.model'

interface FilmEditStupidProps {
  film: Film
  setFilm: (task: Film) => void
}

export const FilmEditStupid = ({ film, setFilm }: FilmEditStupidProps) => {
  function onChange(e: any) {
    const key = e.target.name
    const value = e.target.value
    const edited = { ...film }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    edited[key] = value
    setFilm(edited)
  }

  function onChangeRate(e: any) {
    const edited = { ...film }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    edited.rate = Number(e)
    setFilm(edited)
  }

  return (
    <>
      <FormControl>
        <FormLabel>Status</FormLabel>
        <Select
          name="status"
          placeholder="Select status"
          value={film.status}
          onChange={onChange}
        >
          {Object.keys(FilmStatus).map((key) => (
            <option key={key}>{key}</option>
          ))}
        </Select>
      </FormControl>
      <FormControl mt={4}>
        <Flex>
          <Center>
            <FormLabel>Watch date</FormLabel>
          </Center>
          <Input
            placeholder="Date"
            type="date"
            name="watch_date"
            value={film.watch_date}
            onChange={onChange}
          />
        </Flex>
      </FormControl>
      <FormControl mt={4}>
        <Flex>
          <Center>
            <FormLabel>Rate</FormLabel>
          </Center>
          <NumberInput
            step={1}
            defaultValue={0}
            min={0}
            max={10}
            value={film.rate}
            onChange={onChangeRate}
          >
            <NumberInputField />
          </NumberInput>
        </Flex>
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>My review</FormLabel>
        <Textarea
          name="review"
          placeholder=""
          value={film.review}
          onChange={onChange}
        />
      </FormControl>
    </>
  )
}
