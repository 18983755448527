import moment, { Moment } from 'moment'

export function groupBy<T>(arr: T[], fn: (item: T) => any) {
  const grouped = arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr)
    const group = prev[groupKey] || []
    group.push(curr)
    return { ...prev, [groupKey]: group }
  }, {})
  const map = new Map<string, T[]>()
  Object.keys(grouped).forEach((date) => {
    map.set(date, grouped[date])
  })
  return map
}

export function replaceAllFields<T extends object>(original: T, data: T) {
  Object.keys(data).forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    original[key] = data[key]
  })
}

export function momentToFormatDate(date: Moment): string {
  return date.format('YYYY-MM-DD')
}

export function dateToMoment(date: string): Moment {
  return moment(date, 'YYYY-MM-DD')
}
