import axios from 'axios'
import {
  CreateFlashcardDto,
  Flashcard,
  UpdateFlashcardDto,
} from 'shared/api/flashcard.model'

//http://localhost:4000
const URL = `${
  process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : 'http://localhost:4000'
}/flashcard`

export function findAll() {
  return axios.get<Flashcard[]>(URL, {})
}

export async function create(dto: CreateFlashcardDto) {
  return axios.post<Flashcard>(URL, dto)
}

export async function update(dto: UpdateFlashcardDto) {
  return axios.patch<Flashcard>(URL, dto)
}

export async function ping(id: string) {
  return axios.post<Flashcard>(`${URL}/ping/${id}`)
}

export async function done(id: string) {
  return axios.post<Flashcard>(`${URL}/done/${id}`)
}

export async function undone(id: string) {
  return axios.post<Flashcard>(`${URL}/undone/${id}`)
}

export async function remove(id: string) {
  return axios.delete(`${URL}/${id}`)
}
