import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { Badge, Box, Heading, Image } from '@chakra-ui/react'
import { Film, FilmStatus } from 'shared/api/film.model'
import { Rate } from 'shared/components/Rate'

interface FilmStupidProps {
  film: Film
  onEditFilm?: () => void
  onRemoveFilm?: () => void
}

export const FilmStupid = ({
  film,
  onEditFilm,
  onRemoveFilm,
}: FilmStupidProps) => {
  const details = film.details ?? {}

  const watched =
    film.status === FilmStatus.WATCHED
      ? film.watch_date
        ? film.watch_date
        : 'VIEWED'
      : film.status

  return (
    <>
      <div className="flex-container">
        <Image
          objectFit="cover"
          maxW={{ base: '94px' }}
          maxH={{ base: '141px' }}
          src={details.cover}
          alt={details.title}
          className=""
        />
        <div>
          {<Rate rate={film.rate}></Rate>}
          <Box className="flex-container full-width flex-space-between" pl="2">
            <Heading size="md">{details.title}</Heading>
            <div className="no-flex-grow center">
              <EditIcon onClick={onEditFilm} style={{ cursor: 'pointer' }} />
              <DeleteIcon
                onClick={onRemoveFilm}
                style={{ cursor: 'pointer' }}
              />
              <Badge
                variant="solid"
                colorScheme={
                  film.status === FilmStatus.WATCHED ? 'green' : 'yellow'
                }
              >
                {watched}
              </Badge>
            </div>
          </Box>
          <Box pl="2">
            <pre>{details.releaseDate}</pre>
            <p className="ellipsis">{film.review}</p>
          </Box>
        </div>
      </div>
    </>
  )
}
