import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { BookEdit } from 'features/book/edit/BookEdit'
import React, { useState } from 'react'
import { Book } from 'shared/api/book.model'

interface BookEditWiseProps {
  book: Book
  isOpen: boolean
  onClose: () => void
  onBookSave: (book: Book) => void
  onBookDelete: () => void
}

export const BookEditWise = ({
  book,
  onBookSave,
  isOpen,
  onClose,
  onBookDelete,
}: BookEditWiseProps) => {
  const [editBook, setEditBook] = useState<Book>(book)
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Edit book
            <Button
              colorScheme="red"
              style={{
                float: 'right',
                marginRight: '20px',
              }}
              onClick={onBookDelete}
            >
              Delete
            </Button>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <BookEdit book={editBook} setBook={setEditBook} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" onClick={() => onBookSave(editBook)}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
