import axios from 'axios'
import { QuickAddTask, Task } from 'shared/api/task.model'

//http://localhost:4000
console.log(process.env.REACT_APP_SERVER_URL)
const URL = `${
  process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : 'http://localhost:4000'
}/tasks`

export function fetchAllTasks() {
  return axios.get<Task[]>(URL, {})
}

export async function insertTask(task: QuickAddTask | Task) {
  const taska = task as any
  delete taska.id
  return axios.post<Task>(URL, taska)
}

export async function updateTask(task: Task) {
  return axios.patch<Task>(URL, task)
}

export async function removeTask(id: string) {
  return axios.delete(`${URL}/${id}`)
}
