import { StarIcon } from '@chakra-ui/icons'
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Book } from 'shared/api/book.model'

interface BookProps {
  book: Book
  onEditBook?: () => void
}

export const BookStupid = ({ book, onEditBook }: BookProps) => {
  const details = book.details ?? {}
  return (
    <>
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow="hidden"
        variant="outline"
        style={{
          borderColor: '#2F855A',
          width: '430px',
          alignSelf: 'center',
          justifySelf: 'center',
        }}
      >
        <Image
          objectFit="cover"
          maxW={{ base: '100%', sm: '200px' }}
          maxH={{ base: '100%', sm: '300px' }}
          src={details.cover}
          alt="Caffe Latte"
        />

        <Stack style={{ width: '100%', textAlign: 'left' }}>
          <CardBody>
            <Heading size="md">{details.title}</Heading>
            <Badge>{book.externalServiceId}</Badge>
            <Text py="2">{details.author?.fullname}</Text>
            <Badge pr="2">
              <Heading size="sm">{book.carrier}</Heading>
            </Badge>
            <br />
            <Badge>
              <Heading size="sm">{book.status}</Heading>
            </Badge>
            <Heading size="sm">Start reading: {book.startReading}</Heading>
            <Heading size="sm">Stop reading: {book.endReading}</Heading>
            {book.review && <Text>{book.review}</Text>}
          </CardBody>

          <CardFooter>
            <div style={{ marginLeft: 'auto', display: 'flex' }}>
              <Heading size="xl">
                <StarIcon style={{ marginTop: '-6px' }} color="orange" />{' '}
                {book.rate} - {details.rating}
              </Heading>
              {onEditBook && (
                <Button
                  variant="solid"
                  bgColor="green.600"
                  ml="4"
                  onClick={onEditBook}
                >
                  Edit
                </Button>
              )}
            </div>
          </CardFooter>
        </Stack>
      </Card>
    </>
  )
}
