import {
  Card,
  CardBody,
  CardHeader,
  Input,
  StackDivider,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import * as FilmAPI from 'shared/api/film-api'
import { Film } from 'shared/api/film.model'
import { FilmStupid } from 'features/film/library/FilmStupid'
import { FilmEditModalWise } from 'features/film/film-edit/FilmEditModalWise'

export const FilmLibraryWise = () => {
  const [filter, setFilter] = useState<string>('')
  const [films, setFilms] = useState<Film[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editFilm, setEditFilm] = useState<Film>()

  function onEditFilm(film: Film) {
    setEditFilm(film)
    onOpen()
  }

  useEffect(() => {
    FilmAPI.findAll().then((results) => {
      setFilms(results.data)
    })
  }, [])

  function handleEditFilm(film: Film) {
    FilmAPI.edit(film).then(() => {
      FilmAPI.findAll().then((results) => {
        setFilms(results.data)
      })
      onClose()
    })
  }

  function handleDeleteFilm(id: string) {
    FilmAPI.deletee(id).then(() => {
      setFilms(films.filter((t) => t._id !== id))
      onClose()
    })
  }

  function filterFilms(filter: string) {
    setFilter(filter)
  }

  const filteredFilms = films.filter((film) =>
    film.details.title.toLowerCase().includes(filter.toLowerCase()),
  )
  return (
    <>
      <Card>
        <CardHeader>
          <Input
            placeholder="Filter..."
            value={filter}
            onChange={(e) => filterFilms(e.target.value)}
          ></Input>
        </CardHeader>
        <CardBody>
          <VStack id="robstac" divider={<StackDivider />} spacing={4}>
            {filteredFilms.map((film) => {
              return (
                <FilmStupid
                  key={film.externalServiceId}
                  film={film}
                  onEditFilm={() => onEditFilm(film)}
                ></FilmStupid>
              )
            })}
          </VStack>
        </CardBody>
      </Card>
      {isOpen && editFilm && (
        <FilmEditModalWise
          film={editFilm}
          isOpen={isOpen}
          onClose={onClose}
          onFilmSave={handleEditFilm}
          onFilmDelete={() => handleDeleteFilm(editFilm?._id)}
        />
      )}
    </>
  )
}
