import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react'
import React, { ChangeEvent, useState } from 'react'
import * as FlashcardAPI from 'shared/api/flashcard-api'

interface CreateFlashcardProps {
  onRefresh: () => Promise<void>
}

export default function CreateFlashcard({ onRefresh }: CreateFlashcardProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [question, setQuestion] = useState<string>('')
  const [answer, setAnswer] = useState<string>('')

  const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuestion(e.target.value)
  }

  const handleAnswerChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAnswer(e.target.value)
  }

  const handleCreate = async () => {
    setIsLoading(true)
    await FlashcardAPI.create({
      answer,
      question,
    })
    await onRefresh()

    setQuestion('')
    setAnswer('')

    setIsLoading(false)
  }

  return (
    <Flex direction="column">
      <FormControl mb={4}>
        <FormLabel htmlFor="question">Question</FormLabel>
        <Input
          id="question"
          placeholder="Enter your question"
          value={question}
          onChange={handleQuestionChange}
          disabled={isLoading}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel htmlFor="answer">Answer</FormLabel>
        <Textarea
          id="answer"
          placeholder="Enter your answer"
          value={answer}
          onChange={handleAnswerChange}
          resize="vertical"
          minHeight="200px"
          disabled={isLoading}
        />
      </FormControl>

      <Button
        colorScheme="blue"
        isDisabled={isLoading || !question || !answer}
        onClick={handleCreate}
      >
        Create
      </Button>
    </Flex>
  )
}
