import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { Task } from 'shared/api/task.model'

interface TaskEditStupidProps {
  task: Task
  setTask: (task: Task) => void
}

export const TaskEditStupid = ({ task, setTask }: TaskEditStupidProps) => {
  const initialRef = React.useRef(null)

  function onTaskChange(e: any) {
    const key = e.target.name
    const value = e.target.value
    const edited = { ...task }

    const isNewDateInFuture = moment(value, 'YYYY-MM-DD').isAfter(
      moment(task.date, 'YYYY-MM-DD'),
    )

    if (key === 'date' && !task.carryFrom && isNewDateInFuture) {
      edited.carryFrom = edited.date
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    edited[key] = value

    setTask(edited)
  }

  return (
    <>
      <FormControl>
        <FormLabel>Edit task</FormLabel>
        <Input
          ref={initialRef}
          name="title"
          placeholder=""
          value={task.title}
          onChange={onTaskChange}
        />
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Description</FormLabel>
        <Textarea
          name="description"
          placeholder=""
          value={task.description}
          onChange={onTaskChange}
        />
      </FormControl>

      <FormControl mt={4}>
        <Flex>
          <Center>
            <FormLabel>Date</FormLabel>
          </Center>
          <Input
            placeholder="Date"
            type="date"
            name="date"
            value={task.date}
            onChange={onTaskChange}
          />
        </Flex>
      </FormControl>

      <FormControl mt={4}>
        <Flex>
          <Center>
            <FormLabel>Carry from</FormLabel>
          </Center>
          <Input
            placeholder="Date"
            type="date"
            name="carryFrom"
            value={task.carryFrom}
            onChange={onTaskChange}
          />
        </Flex>
      </FormControl>
    </>
  )
}
