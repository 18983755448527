import { CalendarIcon } from '@chakra-ui/icons'
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import moment from 'moment'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { PlannerDay } from 'shared/utils/PlannerDay'

interface SelectWeekWiseProps {
  selectedDay: PlannerDay
  setSelectedDay: (date: PlannerDay) => void
}

export const SelectWeekWise = ({
  selectedDay,
  setSelectedDay,
}: SelectWeekWiseProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  function onSelectDay(day: Date | undefined) {
    setSelectedDay(PlannerDay.create(moment(day)))
    onToggle()
  }

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button onClick={onToggle} colorScheme="teal" variant="outline">
          <CalendarIcon />
          <Text as="b" fontSize="lg" m={2}>
            {selectedDay.getDate()}
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <DayPicker
            mode="single"
            selected={selectedDay.toDate()}
            onSelect={onSelectDay}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
