import { Box, Input, SimpleGrid } from '@chakra-ui/react'
import { ExternalBook } from 'features/book/add/ExternalBook'
import * as BookAPI from 'shared/api/book-api'
import { BookCarrier, BookExternal, BookStatus } from 'shared/api/book.model'
import useFetch from 'shared/utils/hooks/useFetch'

export const AddBookWise = () => {
  const { data, setData } = useFetch<BookExternal[]>(BookAPI.fetchExternal)

  function onCreateBook(book: BookExternal) {
    BookAPI.create({
      addedDate: new Date().toISOString(),
      externalServiceId: book.externalServiceId,
      title: book.title,
      status: BookStatus.TO_READ,
      carrier: BookCarrier.NOT_OWNED,
    }).then(() => {
      const booksNew = data?.results ? [...data.results] : []
      const bookNew = booksNew.find(
        (b) => b.externalServiceId === book.externalServiceId,
      )
      if (bookNew) {
        bookNew.possess = true
        setData({ slug: data.slug, results: [...booksNew] })
      } else {
        /// TODO ERROR
      }
    })
  }

  return (
    <>
      <Box p="40px">
        <Input
          placeholder="Start searching..."
          value={data.slug}
          onChange={(e) => setData({ ...data, slug: e.target.value })}
        ></Input>
      </Box>
      <SimpleGrid columns={[1, 1, 2]} spacing="10px" padding="40px">
        {data?.results?.map((book) => {
          return (
            <ExternalBook
              key={+book.externalServiceId}
              book={book}
              onAddBook={() => onCreateBook(book)}
            ></ExternalBook>
          )
        })}
      </SimpleGrid>
    </>
  )
}
