export type FilmExternal = {
  externalServiceId: string
  title: string
  cover: string
  description: string
  releaseDate: string
  possess: boolean
}

export interface CreateMovieDto {
  externalServiceId: string
  title: string
  status: FilmStatus
  addedDate: string
}

export interface Film {
  _id: string
  addedDate: Date
  externalServiceId: string
  status: string
  watch_date: string
  rate: number
  review: string
  details: FilmDetails
}

export interface FilmDetails {
  _id: string
  id: string
  __v: number
  cover: string
  title: string
  releaseDate: string
}

export enum FilmStatus {
  TO_WATCH = 'TO_WATCH',
  NOT_REMEMBER = 'NOT_REMEMBER',
  WATCHED = 'WATCHED',
}
