import { Button, Card, CardBody, Skeleton, Stack } from '@chakra-ui/react'
import { Answer } from 'features/flashcard/answer'
import EditFlashcard from 'features/flashcard/edit-flashcard'
import { FlashCardContext } from 'features/flashcard/flashcard.context'
import { PingBadge } from 'features/flashcard/ping-badge'
import { useContext, useState } from 'react'
import * as FlashcardAPI from 'shared/api/flashcard-api'
import { Flashcard } from 'shared/api/flashcard.model'
import { Question } from 'features/flashcard/question'

enum MODE {
  QUESTION,
  ANSWER,
  PING,
  DONE,
  EDIT,
}

interface FlashcardComponentProps {
  onRefresh: (flashcard: Flashcard) => Promise<void>
  onEditBook?: () => void
}

export function FlashcardComponent({ onRefresh }: FlashcardComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [mode, setMode] = useState<MODE>(
    useContext(FlashCardContext).done ? MODE.DONE : MODE.QUESTION,
  )

  const id = useContext(FlashCardContext)._id

  async function ping() {
    setIsLoading(true)
    onRefresh((await FlashcardAPI.ping(id)).data)
    setMode(MODE.PING)
    setIsLoading(false)
  }

  async function onDone() {
    setIsLoading(true)
    await onRefresh((await FlashcardAPI.done(id)).data)
    setMode(MODE.DONE)
    setIsLoading(false)
  }

  function onShow() {
    setMode(MODE.ANSWER)
  }

  function backgroundColor() {
    if (isLoading) return 'planner.white'
    if (mode === MODE.PING) return 'planner.yellow'
    if (mode === MODE.DONE) return 'planner.green'
    return 'white'
  }

  async function remove() {
    setIsLoading(true)
    await onRefresh((await FlashcardAPI.remove(id)).data)
    setIsLoading(false)
  }

  function content() {
    if (isLoading) {
      return (
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="40px" />
        </Stack>
      )
    }
    if (mode === MODE.QUESTION) {
      return (
        <>
          <div className="flex-container">
            <Question />
            <Button className="no-flex-grow center" onClick={onShow}>
              SHOW
            </Button>
          </div>
        </>
      )
    }

    if (mode === MODE.ANSWER) {
      return (
        <>
          <div className="flex-container">
            <Question />
            <Button
              className="no-flex-grow center"
              bgColor="planner.red"
              onClick={() => remove()}
              marginRight="1rem"
            >
              Remove
            </Button>
            <Button
              className="no-flex-grow center"
              bgColor="planner.yellow"
              onClick={() => setMode(MODE.EDIT)}
              marginRight="1rem"
            >
              Edit
            </Button>
            <Button
              className="no-flex-grow center"
              bgColor="planner.yellow"
              onClick={() => ping()}
              marginRight="1rem"
            >
              PING
            </Button>
            <Button
              className="no-flex-grow center"
              bgColor="planner.green"
              onClick={onDone}
            >
              DONE
            </Button>
          </div>
          <Answer />
        </>
      )
    }

    if (mode === MODE.PING) {
      return (
        <>
          <div className="flex-container">
            <Question />
            <Button
              className="no-flex-grow center"
              onClick={() => setMode(MODE.ANSWER)}
            >
              SHOW
            </Button>
          </div>
        </>
      )
    }

    if (mode === MODE.DONE) {
      return (
        <>
          <Question />
          <Answer />
        </>
      )
    }

    if (mode === MODE.EDIT) {
      return (
        <>
          <EditFlashcard
            onRefresh={async (flashCard: Flashcard) => {
              await onRefresh(flashCard)
              setMode(MODE.QUESTION)
            }}
          />
        </>
      )
    }
  }

  return (
    <Card bgColor={backgroundColor()} style={{ margin: '0 0 1rem 0' }}>
      <CardBody>
        {!isLoading && <PingBadge />}
        {content()}
      </CardBody>
    </Card>
  )
}
