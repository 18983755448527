import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import 'index.css'
import { Locker } from 'shared/components/Locker'
import App from 'App'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const theme = extendTheme({
  colors: {
    planner: {
      black: '#28262C',
      white: '#FAFAFA',
      red: '#A64253',
      yellow: '#F2A541',
      green: '#ABD1B5',
      gray: '#424B54',
    },
  },
})

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Locker>
        <App />
      </Locker>
    </ChakraProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
