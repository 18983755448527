import axios from 'axios'
import { CreateMovieDto, Film, FilmExternal } from 'shared/api/film.model'
import { BookExternal } from 'shared/api/book.model'

const URL = `${
  process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : 'http://localhost:4000'
}/films`

export function fetchExternal(title: string, page = 1) {
  return axios.get<FilmExternal[]>(
    URL + `/find?query=${title}&page=${page}`,
    {},
  )
}

export function create(movie: CreateMovieDto) {
  return axios.post<BookExternal[]>(URL, movie)
}

export async function deletee(id: string) {
  return axios.delete(`${URL}/${id}`)
}

export function findAll() {
  return axios.get<Film[]>(URL)
}

export function edit(film: Film) {
  return axios.patch<Film>(URL, film)
}

/*



export async function deletee(id: string) {
  return axios.delete(`${URL}/${id}`)
}*/ /*export function edit(book: Moview) {
  return axios.patch<Book>(URL, book)
}

export function findAll() {
  return axios.get<Book[]>(URL)
}

*/
