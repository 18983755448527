import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { REMOVE_CONFIRMATION } from 'config/app.config'
import moment from 'moment/moment'
import { Task } from 'shared/api/task.model'
import { AlertDialogConfirm } from 'shared/components/AlertDialogConfirm'
import { TaskItemIconStupid } from 'features/dashboard/task/task-list/task-item/task-item-atom/TaskItemIconStupid'

interface TaskItemStupidProps {
  task: Task
  onTaskEdit: (task: Task) => void
  onTaskEditClick: () => void
  onTaskRemove: (id: string) => void
}

export const TaskItemStupid = ({
  task,
  onTaskEdit,
  onTaskEditClick,
  onTaskRemove,
}: TaskItemStupidProps) => {
  const disclosure = useDisclosure()

  function onTaskCompleted() {
    onTaskEdit({ ...task, completed: !task.completed })
  }

  function onRemoveClicked() {
    if (REMOVE_CONFIRMATION) {
      disclosure.onOpen()
    } else {
      onTaskRemove(task._id)
    }
  }

  console.log(task.description)
  return (
    <>
      <Box>
        <div>
          <Flex>
            <Spacer />
            {task.carryFrom && (
              <Heading
                size="xs"
                color="red.300"
                style={{ marginTop: '-12px', marginRight: '2px' }}
              >
                {moment(task.carryFrom).format('DD.MM.YYYY')}
              </Heading>
            )}
            <EditIcon
              onClick={onTaskEditClick}
              style={{
                marginTop: '-12px',
                marginRight: '4px',
                cursor: 'pointer',
              }}
            />
            <DeleteIcon
              onClick={onRemoveClicked}
              style={{
                marginTop: '-12px',
                marginRight: '-12px',
                cursor: 'pointer',
              }}
            />
          </Flex>
          <Flex>
            <TaskItemIconStupid
              completed={task.completed}
              setCompleted={onTaskCompleted}
            />
            <Heading
              size="sm"
              as={task.completed ? 'del' : 'b'}
              style={{
                width: '100%',
                flex: 'none',
                textAlign: 'left',
              }}
            >
              {task.title}
            </Heading>
          </Flex>
        </div>
        <Text pt="2" fontSize="sm">
          {task.description &&
            task.description
              .split('\n')
              .map((text, index) => <Text key={index}>{text}</Text>)}
        </Text>
      </Box>
      <AlertDialogConfirm
        header="Remove task"
        body={`Are you sure to remove task: "${task.title}"`}
        disclosure={disclosure}
        submit={() => onTaskRemove(task._id)}
      ></AlertDialogConfirm>
    </>
  )
}
