import axios from 'axios'
import { Book, BookExternal, CreateBookDto } from 'shared/api/book.model'

const URL = `${
  process.env.REACT_APP_SERVER_URL
    ? process.env.REACT_APP_SERVER_URL
    : 'http://localhost:4000'
}/books`

export function fetchExternal(title: string) {
  return axios.get<BookExternal[]>(URL + `/findHTML?query=${title}`, {})
}

export function create(createBookDto: CreateBookDto) {
  return axios.post<BookExternal[]>(URL, createBookDto)
}

export function edit(book: Book) {
  return axios.patch<Book>(URL, book)
}

export function findAll() {
  return axios.get<Book[]>(URL)
}

export async function deletee(id: string) {
  return axios.delete(`${URL}/${id}`)
}
