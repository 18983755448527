import {
  Card,
  CardBody,
  Heading,
  Stack,
  StackDivider,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Task } from 'shared/api/task.model'
import { TaskItemStupid } from 'features/dashboard/task/task-list/task-item/TaskItemStupid'
import { TaskQuickAddWise } from 'features/dashboard/task/task-list/task-item/TaskQuickAddWise'
import { TaskEditModalWise } from 'features/dashboard/task/task-edit-modal/TaskEditModalWise'

interface TaskListWiseProps {
  tasks: Task[]
  onTaskEdit: (task: Task, afterSaveCallback?: () => void) => void
  onTaskQuickAdd: (title: string) => void
  onTaskRemove: (id: string) => void
  isMain: boolean
}

export const TaskListWise = ({
  tasks,
  onTaskEdit,
  onTaskQuickAdd,
  isMain,
  onTaskRemove,
}: TaskListWiseProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editTask, setEditTask] = useState<Task>()

  function handleTaskFullEdit(task: Task) {
    setEditTask(task)
    onOpen()
  }

  function handleTaskModalEditSave(task: Task) {
    onTaskEdit(task, () => onClose())
  }

  const cardDisplay = {
    base: isMain ? 'block' : 'none',
    sm: isMain ? 'block' : 'none',
    md: 'block',
    lg: 'block',
    xl: 'block',
    '2xl': 'block',
  }
  return (
    <>
      <Card opacity={isMain ? '100%' : '40%'} display={cardDisplay} mb="20px">
        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            {!tasks.length && <Heading>No tasks for today</Heading>}
            {tasks.map((task) => (
              <TaskItemStupid
                key={task._id}
                task={task}
                onTaskEdit={onTaskEdit}
                onTaskEditClick={() => handleTaskFullEdit(task)}
                onTaskRemove={onTaskRemove}
              />
            ))}
            <TaskQuickAddWise onTaskAdd={onTaskQuickAdd} />
          </Stack>
        </CardBody>
      </Card>
      {isOpen && editTask && (
        <TaskEditModalWise
          task={editTask}
          isOpen={isOpen}
          onClose={onClose}
          onTaskSave={handleTaskModalEditSave}
        />
      )}
    </>
  )
}
