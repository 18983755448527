import moment, { Moment } from 'moment'

export class PlannerDay {
  public static readonly FORMAT_DATE = 'YYYY-MM-DD'
  private readonly date: string

  private constructor(date: Moment | string) {
    if (moment.isMoment(date)) {
      this.date = date.format(PlannerDay.FORMAT_DATE)
    } else {
      this.date = moment(date, PlannerDay.FORMAT_DATE).format(
        PlannerDay.FORMAT_DATE,
      ) // TODO validate
    }
  }

  static create(date: Moment | string) {
    return new PlannerDay(date)
  }

  static today() {
    return new PlannerDay(moment())
  }

  getDate() {
    return this.date
  }

  getMoment() {
    return moment(this.date, PlannerDay.FORMAT_DATE)
  }

  isSame(date: PlannerDay) {
    return this.date === date.getDate()
  }

  hash() {
    return this.date // TODO real hash
  }

  getWeekname() {
    return moment(this.date, PlannerDay.FORMAT_DATE).format('dddd')
  }

  isoWeekday() {
    return this.getMoment().isoWeekday()
  }

  addDay(amount: number) {
    return PlannerDay.create(this.getMoment().add(amount, 'day'))
  }

  toDate() {
    const split = this.date.split('-').map((v) => Number(v))
    return new Date(split[0], split[1] - 1, split[2])
  }
}
