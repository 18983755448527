import { useEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/ban-types
const useFetch = <T>(api: Function) => {
  const [data, setData] = useState<{
    slug: string
    results?: T
  }>({
    slug: '',
    results: undefined,
  })

  useEffect(() => {
    if (data.slug !== '') {
      const timeoutId = setTimeout(() => {
        const fetch = async () => {
          try {
            const res = await api(data.slug)
            setData({ ...data, results: res.data })
          } catch (err) {
            console.error(err)
          }
        }
        fetch()
      }, 700)
      return () => clearTimeout(timeoutId)
    }
  }, [data.slug])

  return { data, setData }
}

export default useFetch
