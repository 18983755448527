import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { FilmExternal } from 'shared/api/film.model'

interface ExternalMoviewProps {
  film: FilmExternal
  onAddMovie: () => void
}

export const ExternalMovie = ({ film, onAddMovie }: ExternalMoviewProps) => {
  return (
    <>
      <Card
        direction={{ base: 'column', sm: 'row' }}
        overflow="hidden"
        variant="outline"
        style={{ borderColor: '#2F855A' }}
      >
        <Image
          objectFit="cover"
          maxW={{ base: '100%', sm: '200px' }}
          src={film.cover}
          alt={film.title}
        />
        <Stack style={{ width: '100%' }}>
          <CardBody style={{ overflow: 'hidden' }}>
            <Heading size="md">{film.title}</Heading>
            <Badge>{film.externalServiceId}</Badge>
            <Text py="2">{film.releaseDate}</Text>
            <Text py="1">{film.description}</Text>
          </CardBody>

          <CardFooter>
            <div style={{ marginLeft: 'auto' }}>
              <Tooltip label={film.possess ? 'Already possess' : ''}>
                <Button
                  variant="solid"
                  bgColor="green.600"
                  mt="4"
                  onClick={onAddMovie}
                  isDisabled={film.possess}
                >
                  Add film
                </Button>
              </Tooltip>
            </div>
          </CardFooter>
        </Stack>
      </Card>
    </>
  )
}
