import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { Task } from 'shared/api/task.model'
import { TaskEditStupid } from 'features/dashboard/task/task-edit-modal/task-edit/TaskEditStupid'

interface TaskEditModalProps {
  task: Task
  isOpen: boolean
  onClose: () => void
  onTaskSave: (task: Task) => void
}

export const TaskEditModalWise = ({
  task,
  onTaskSave,
  isOpen,
  onClose,
}: TaskEditModalProps) => {
  const [editTask, setEditTask] = useState<Task>(task)

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TaskEditStupid task={editTask} setTask={setEditTask} />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="ghost" onClick={() => onTaskSave(editTask)}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
