export interface Flashcard {
  _id: string
  createDate: string
  question: string
  answer: string
  ping?: string[]
  done: boolean
}

export const FLASHCARD_EMPTY = {
  _id: '',
  createDate: '',
  question: '',
  answer: '',
  ping: [],
  done: false,
}

export type CreateFlashcardDto = Pick<Flashcard, 'question' | 'answer'>
export type UpdateFlashcardDto = Pick<Flashcard, 'question' | 'answer' | '_id'>
