import { Progress } from '@chakra-ui/react'

interface RateProps {
  rate: number
}

const RATE_COLOR = {
  0: 'red', // no use
  1: 'red',
  2: 'red',
  3: 'orange',
  4: 'orange',
  5: 'yellow',
  6: 'yellow',
  7: 'teal',
  8: 'teal',
  9: 'green',
  10: 'green',
}

/*******How to test RATE***********
 {Array.from(Array(11).keys()).map((r) => {
 return <Rate rate={r}></Rate>
 })}}
 */

export const Rate = ({ rate }: RateProps) => {
  const colorScheme = Object.values(RATE_COLOR)[rate] ?? false
  return (
    <>
      {colorScheme && colorScheme && (
        <Progress
          value={rate}
          max={10}
          min={0}
          size="xs"
          bgColor="white"
          colorScheme={colorScheme}
          hasStripe={true}
        ></Progress>
      )}
    </>
  )
}
