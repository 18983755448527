import { Input } from '@chakra-ui/react'
import { useState } from 'react'

interface TaskQuickAddStupidProps {
  onTaskAdd: (value: string) => any
}

export const TaskQuickAddWise = ({ onTaskAdd }: TaskQuickAddStupidProps) => {
  const [input, setInput] = useState<string>('')

  function onAddNewTask(event: any) {
    if (event.key === 'Enter') {
      onTaskAdd(input)
      setInput('')
    }
  }

  return (
    <>
      <Input
        placeholder="Add new item"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={onAddNewTask}
      />
    </>
  )
}
