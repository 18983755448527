import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { CalendarWeekStupid } from 'features/dashboard/calendar/CalendarWeekStupid'
import { SelectWeekWise } from 'features/dashboard/task/task-week-calendar/SelectWeekWise'
import { TaskContainerWise } from 'features/dashboard/task/TaskContainerWise'
import { useState } from 'react'
import { PlannerDay } from 'shared/utils/PlannerDay'

export const DashboardWise = () => {
  const [week, setWeek] = useState<PlannerDay[]>(
    calculateWeekFromDay(PlannerDay.today()),
  )
  const [selectedDay, setSelectedDay] = useState<PlannerDay>(PlannerDay.today())

  function handleSelectDay(day: PlannerDay) {
    setWeek(calculateWeekFromDay(day))
    setSelectedDay(day)
  }

  function calculateWeekFromDay(day: PlannerDay) {
    return [
      day.getMoment().weekday(1),
      day.getMoment().weekday(2),
      day.getMoment().weekday(3),
      day.getMoment().weekday(4),
      day.getMoment().weekday(5),
    ].map((day) => PlannerDay.create(day))
  }

  return (
    <>
      <Flex>
        <SelectWeekWise
          selectedDay={selectedDay}
          setSelectedDay={handleSelectDay}
        />
        <Link href={'task/add'}>
          <Button colorScheme="teal" variant="outline">
            <Text as="b" fontSize="lg" m={2}>
              Add
            </Text>
          </Button>
        </Link>
        <CalendarWeekStupid
          week={week}
          selectedDay={selectedDay}
          setSelectedDay={handleSelectDay}
        ></CalendarWeekStupid>
      </Flex>
      <TaskContainerWise day={selectedDay} />
    </>
  )
}
