import { TabIndicator, TabList, Tabs } from '@chakra-ui/react'
import { CalendarItemStupid } from 'features/dashboard/calendar/CalendarItemStupid'
import { PlannerDay } from 'shared/utils/PlannerDay'

interface CalendarWeekWiseProps {
  week: PlannerDay[]
  selectedDay: PlannerDay
  setSelectedDay: (day: PlannerDay) => void
}

export const CalendarWeekStupid = ({
  week,
  selectedDay,
  setSelectedDay,
}: CalendarWeekWiseProps) => {
  const index = week.findIndex((day) => day.isSame(selectedDay))
  return (
    <>
      <Tabs
        isFitted
        mb={4}
        index={index}
        defaultIndex={index}
        onChange={(index) => setSelectedDay(week[index])}
        style={{ flex: 'auto' }}
      >
        <TabList>
          {week.map((day) => (
            <CalendarItemStupid
              key={day.hash()}
              day={day}
              onSelectDay={() => setSelectedDay(day)}
            />
          ))}
        </TabList>
        <TabIndicator
          mt="-1.5px"
          height="2px"
          bg="blue.500"
          borderRadius="1px"
        ></TabIndicator>
      </Tabs>
    </>
  )
}
