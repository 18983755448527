import { Button, Link } from '@chakra-ui/react'
import { TaskEditStupid } from 'features/dashboard/task/task-edit-modal/task-edit/TaskEditStupid'
import React, { useState } from 'react'
import { insertTask } from 'shared/api/task-api'
import { Task } from 'shared/api/task.model'
import { PlannerDay } from 'shared/utils/PlannerDay'
import { useLocation } from 'wouter'

const TODAY_TASK: Task = {
  _id: '',
  carryFrom: undefined,
  completed: false,
  date: PlannerDay.today().getDate(),
  description: '',
  title: '',
}

export const AddTaskWise = () => {
  const [task, setTask] = useState<Task>(TODAY_TASK)
  const [, navigate] = useLocation()

  function handleSave() {
    insertTask(task).then(() => {
      navigate('/')
    })
  }

  return (
    <>
      <TaskEditStupid task={task} setTask={setTask}></TaskEditStupid>
      <Link href="/">
        <Button>Cancel</Button>
      </Link>
      <Button onClick={handleSave}>Save</Button>
    </>
  )
}
