import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
} from '@chakra-ui/react'
import React from 'react'
import { Book, BookStatus } from 'shared/api/book.model'

interface BookEditProps {
  book: Book
  setBook: (task: Book) => void
}

export const BookEdit = ({ book, setBook }: BookEditProps) => {
  function onChange(e: any) {
    const key = e.target.name
    const value = e.target.value
    const edited = { ...book }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    edited[key] = value
    setBook(edited)
  }

  function onChangeRate(e: any) {
    const edited = { ...book }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    edited.rate = Number(e)
    setBook(edited)
  }

  return (
    <>
      <FormControl mt={4}>
        <Flex>
          <Center>
            <FormLabel>Carry from</FormLabel>
          </Center>
          <Input
            placeholder="Date"
            type="date"
            name="addedDate"
            value={book.addedDate}
            onChange={onChange}
          />
        </Flex>
      </FormControl>

      <FormControl mt={4}>
        <Flex>
          <Center>
            <FormLabel>Start reading</FormLabel>
          </Center>
          <Input
            placeholder="Date"
            type="date"
            name="startReading"
            value={book.startReading}
            onChange={onChange}
          />
        </Flex>
      </FormControl>

      <FormControl mt={4}>
        <Flex>
          <Center>
            <FormLabel>End reading</FormLabel>
          </Center>
          <Input
            placeholder="Date"
            type="date"
            name="endReading"
            value={book.endReading}
            onChange={onChange}
          />
        </Flex>
      </FormControl>

      <FormControl mt={4}>
        <Flex>
          <Center>
            <FormLabel>Rate</FormLabel>
          </Center>
          <NumberInput
            step={1}
            defaultValue={0}
            min={0}
            max={10}
            value={book.rate}
            onChange={onChangeRate}
          >
            <NumberInputField />
          </NumberInput>
        </Flex>
      </FormControl>

      <FormControl>
        <FormLabel>Status</FormLabel>
        <Select
          name="status"
          placeholder="Select status"
          value={book.status}
          onChange={onChange}
        >
          {Object.keys(BookStatus).map((key) => (
            <option key={key}>{key}</option>
          ))}
        </Select>
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>My review</FormLabel>
        <Textarea
          name="review"
          placeholder=""
          value={book.review}
          onChange={onChange}
        />
      </FormControl>
    </>
  )
}
