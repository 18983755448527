import { Flex, Link } from '@chakra-ui/react'

const LINKS = [
  { name: 'GitHub', link: 'https://github.com/roobiN28/Planner' },
  {
    name: 'Railway',
    link: 'https://railway.app/project/db5ac46c-7d11-43c4-956b-246bc7514f20',
  },
  {
    name: 'Firebase',
    link: 'https://console.firebase.google.com/u/0/project/planner-rob/overview',
  },
  { name: 'Chakra', link: 'https://chakra-ui.com' },
  { name: 'DayPicker', link: 'https://react-day-picker.js.org/' },
]
export const FooterStupid = () => {
  return (
    <>
      <footer id="footer">
        <Flex flexDirection="row-reverse">
          {LINKS.map((link) => (
            <Link pr="12px" key={link.link} href={link.link} isExternal>
              {link.name}
            </Link>
          ))}
        </Flex>
      </footer>
    </>
  )
}
