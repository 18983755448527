import CreateFlashcard from 'features/flashcard/create-flashcard'
import { FlashcardComponent } from 'features/flashcard/flashcardComponent'
import { useEffect, useState } from 'react'
import * as FlashcardAPI from 'shared/api/flashcard-api'
import { Flashcard } from 'shared/api/flashcard.model'
import { FlashCardContext } from 'features/flashcard/flashcard.context'

export function FlashcardList() {
  const [flashcards, setFlashcards] = useState<Flashcard[]>([])

  useEffect(() => {
    FlashcardAPI.findAll().then((results) => {
      setFlashcards(results.data)
    })
  }, [])

  async function refresh() {
    return FlashcardAPI.findAll().then((results) => {
      setFlashcards(results.data)
    })
  }

  return (
    <>
      {flashcards.map((flashcard) => {
        return (
          <FlashCardContext.Provider value={flashcard} key={flashcard._id}>
            <FlashcardComponent onRefresh={refresh} />
          </FlashCardContext.Provider>
        )
      })}
      <CreateFlashcard onRefresh={refresh} />
    </>
  )
}
