import { Box, Input, SimpleGrid } from '@chakra-ui/react'
import * as FilmAPI from 'shared/api/film-api'
import { FilmExternal, FilmStatus } from 'shared/api/film.model'
import useFetch from 'shared/utils/hooks/useFetch'
import { ExternalMovie } from 'features/film/add-film/ExternalMovie'

export const AddFilmWise = () => {
  const { data, setData } = useFetch<FilmExternal[]>(FilmAPI.fetchExternal)

  function onCreateFilm(film: FilmExternal) {
    FilmAPI.create({
      addedDate: new Date().toISOString(),
      externalServiceId: film.externalServiceId,
      title: film.title,
      status: FilmStatus.TO_WATCH,
    }).then(() => {
      const filmsNew = data?.results ? [...data.results] : []
      const filmNew = filmsNew.find(
        (b) => b.externalServiceId === film.externalServiceId,
      )
      if (filmNew) {
        filmNew.possess = true
        setData({ slug: data.slug, results: [...filmsNew] })
      } else {
        /// TODO ERROR
      }
    })
  }

  console.log(data?.results)
  return (
    <>
      <Box p="40px">
        <Input
          placeholder="Start searching..."
          value={data.slug}
          onChange={(e) => setData({ ...data, slug: e.target.value })}
        ></Input>
      </Box>
      <SimpleGrid columns={[1, 1, 2]} spacing="10px" padding="40px">
        {data?.results?.map((film) => {
          return (
            <ExternalMovie
              key={film.externalServiceId}
              film={film}
              onAddMovie={() => onCreateFilm(film)}
            ></ExternalMovie>
          )
        })}
      </SimpleGrid>
    </>
  )
}
