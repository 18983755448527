import { SimpleGrid, useDisclosure } from '@chakra-ui/react'
import { BookEditWise } from 'features/book/edit/BookEditWise'
import { BookStupid } from 'features/book/library/BookStupid'
import { useEffect, useState } from 'react'
import * as BookAPI from 'shared/api/book-api'
import { Book } from 'shared/api/book.model'

export const LibraryWise = () => {
  const [books, setBooks] = useState<Book[]>([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editBook, setEditBook] = useState<Book>()

  function onEditBook(book: Book) {
    setEditBook(book)
    onOpen()
  }

  useEffect(() => {
    BookAPI.findAll().then((results) => {
      setBooks(results.data)
    })
  }, [])

  function handleEditBook(book: Book) {
    BookAPI.edit(book).then(() => {
      BookAPI.findAll().then((results) => {
        setBooks(results.data)
      })
      onClose()
    })
  }

  function handleDeleteBook(id: string) {
    BookAPI.deletee(id).then(() => {
      setBooks(books.filter((t) => t._id !== id))
      onClose()
    })
  }

  return (
    <>
      <SimpleGrid minChildWidth="420px" spacing="40px" padding="40px">
        {books.map((book) => {
          return (
            <BookStupid
              key={book.externalServiceId}
              book={book}
              onEditBook={() => onEditBook(book)}
            ></BookStupid>
          )
        })}
      </SimpleGrid>
      {isOpen && editBook && (
        <BookEditWise
          book={editBook}
          isOpen={isOpen}
          onClose={onClose}
          onBookSave={handleEditBook}
          onBookDelete={() => handleDeleteBook(editBook?._id)}
        />
      )}
    </>
  )
}
